/** @jsxImportSource @emotion/react */
import React, {
	useRef,
	forwardRef,
	ForwardedRef,
	InputHTMLAttributes,
	TextareaHTMLAttributes,
} from "react";
import Label from "components/atoms/Label";
import { COLOR } from "styles/common";
import { BaseTypes } from "types/common";
import { stylingText } from "libs/styling";

interface InputLabelType extends BaseTypes {
	title: string;
	required?: boolean;
	description?: string;
	activate?: boolean;
	isDarkMode?: boolean;
}

type InputBoxType = InputHTMLAttributes<HTMLInputElement> & {
	isDarkMode?: boolean;
	isError?: boolean;
	lengthLimit?: number;
	errorMessage?: string;
};

type TextAreaBoxType = TextareaHTMLAttributes<HTMLTextAreaElement> & {
	autoHeight?: boolean;
	isDarkMode?: boolean;
	isError?: boolean;
	lengthLimit?: number;
	isResizable?: boolean;
	errorMessage?: string;
};

export const Input = {
	Wrapper: ({ children, ...props }: BaseTypes) => <div {...props}>{children}</div>,

	Label: ({
		title,
		required,
		description,
		activate = true,
		isDarkMode = false,
		children,
		...props
	}: InputLabelType) => (
		<Label css={label(activate, isDarkMode)} title={title} required={required} {...props}>
			{children || (description && <span css={styleDescription}>{description}</span>)}
		</Label>
	),

	Info: ({ message, activate = true }: { message?: string; activate?: boolean }) => {
		if (!message) return null;
		return <p css={info(activate)}>{message}</p>;
	},

	Box: forwardRef(
		(
			{
				id,
				isError,
				isDarkMode = false,
				lengthLimit,
				errorMessage,
				...inputProps
			}: InputBoxType,
			ref: ForwardedRef<HTMLInputElement>,
		) => {
			const { value } = inputProps;
			const isLimited = (value as string)?.length > lengthLimit;
			return (
				<div css={inputBox}>
					<input
						id={id}
						ref={ref}
						autoComplete="new-password"
						css={inputStyle(isDarkMode, isError || isLimited)}
						{...inputProps}
					/>
					{isError && !isLimited && <p css={error}>{errorMessage}</p>}
					{isLimited && (
						<p css={lengthInfo}>{`${(value as string)?.length} / ${lengthLimit}`}</p>
					)}
				</div>
			);
		},
	),

	Area: ({
		isError,
		id,
		autoHeight = false,
		isDarkMode = false,
		lengthLimit,
		isResizable = false,
		errorMessage,
		...inputProps
	}: TextAreaBoxType) => {
		const textareaRef = useRef<HTMLTextAreaElement>(null);
		const textarea = textareaRef.current;
		const { value } = inputProps;
		const isLimited = (value as string)?.length > lengthLimit;
		if (autoHeight && textarea) {
			textarea.style.height = "auto";
			textarea.style.height = `${textarea.scrollHeight}px`;
		}
		return (
			<div css={inputBox}>
				<textarea
					css={areaStyle(isDarkMode, isResizable, isError || isLimited)}
					ref={textareaRef}
					id={id}
					{...inputProps}
				/>
				{isError && !isLimited && <p css={error}>{errorMessage}</p>}
				{isLimited && (
					<p css={lengthInfo}>{`${(value as string)?.length} / ${lengthLimit}`}</p>
				)}
			</div>
		);
	},
};

const label = (activate: boolean, isDarkMode: boolean) => ({
	...stylingText({
		size: "14",
		weight: "medium",
		color: isDarkMode ? "white" : activate ? COLOR.gray900 : COLOR.gray400,
	}),
});

const styleDescription = {
	display: "flex",
	alignItems: "center",
	marginLeft: "12px",
};

const defaultStyles = (isDarkMode: boolean, isInvalid?: boolean) => ({
	borderRadius: "5px",

	...stylingText({ size: "14", weight: "medium" }),

	"&:active": {
		boxShadow: "none",
	},

	"&:focus-visible": {
		outline: "none" as const,
		borderColor: isInvalid ? COLOR.red : isDarkMode ? COLOR.gray200 : COLOR.gray900,
	},

	"&:disabled": {
		borderColor: isDarkMode ? COLOR.gray700 : COLOR.gray400,
		color: isDarkMode ? COLOR.gray700 : COLOR.gray400,
		"&::placeholder": {
			color: isDarkMode ? COLOR.gray700 : COLOR.gray400,
			...stylingText({ size: "14", weight: "medium" }),
		},
	},

	"&::placeholder": {
		...stylingText({ size: "14", weight: "medium", color: COLOR.gray500 }),
	},
});

const inputBox = { position: "relative" as const, display: "block", width: "100%" };

const inputStyle = (isDarkMode: boolean, isInvalid?: boolean) => ({
	display: "block",
	width: "100%",
	height: "48px",
	padding: "10px 16px",
	backgroundClip: "padding-box",
	appearance: "none" as const,
	border: `1px solid ${isInvalid ? COLOR.red : COLOR.gray500}`,
	backgroundColor: isDarkMode ? COLOR.gray900 : COLOR.white,
	color: isDarkMode ? COLOR.gray200 : COLOR.gray900,
	...defaultStyles(isDarkMode, isInvalid),
});

const areaStyle = (isDarkMode: boolean, isResizable?: boolean, isInvalid?: boolean) => ({
	height: "96px",
	padding: "8px 16px",
	overflow: "hidden" as const,
	border: `1px solid ${isInvalid ? COLOR.red : COLOR.gray500}`,
	resize: isResizable ? ("vertical" as const) : ("none" as const),
	...defaultStyles(isDarkMode, isInvalid),
});

const error = {
	marginTop: "4px",
	color: COLOR.red,
	...stylingText({ size: "12" }),
};

const info = (activate?: boolean) => ({
	marginTop: "4px",
	color: activate ? COLOR.gray500 : COLOR.gray400,
	...stylingText({ size: "12" }),
});

const lengthInfo = {
	textAlign: "right" as const,
	marginTop: "4px",
	color: COLOR.red,
	...stylingText({ size: "12" }),
};
