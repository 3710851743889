/** @jsxImportSource @emotion/react */
import { keyframes } from "@emotion/react";
import { COLOR } from "styles/common";

interface PropsType {
	width: string;
	height: string;
	fontSize: string;
}

export default function Spinner({ width, height, fontSize }: PropsType) {
	return (
		<div css={LoadingContainer(width, height)}>
			<div css={Loading(width, height)} />
			<div css={LoadingText(width, fontSize)}>LOADING</div>
		</div>
	);
}

const rotateLoading = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

const loadingTextOpacity = keyframes`
    0% { opacity: 0; }
    20% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
`;

const LoadingContainer = (width: string, height: string) => ({
	position: "relative" as const,
	width: `${width}px`,
	height: `${height}px`,
	borderRadius: "100%",
});

const Loading = (width: string, height: string) => ({
	width: `${width}px`,
	height: `${height}px`,
	border: "2px solid transparent",
	borderColor: `transparent ${COLOR.main} transparent ${COLOR.main}`,
	borderRadius: "100%",
	animation: `${rotateLoading} 1.5s linear infinite`,
	transformOrigin: "50% 50%",
	transition: "all 0.5s ease-in-out",
});

const LoadingText = (width: string, fontSize: string) => ({
	width: `${width}px`,
	position: "absolute" as const,
	top: "50%",
	transform: "translate(0, -50%)",
	// marginTop: "42px",
	textAlign: "center" as const,
	fontSize: `${fontSize}px`,
	fontWeight: "bold",
	color: COLOR.main,
	animation: `${loadingTextOpacity} 1.5s linear infinite`,
});
