/** @jsxImportSource @emotion/react */
import { useAtomValue } from "jotai";
import { loadingAtom } from "jotai/modal/loadingAtom";
import Modal from "components/layout/Modal";
import Spinner from "components/modules/Spinner";

export default function Loading() {
	const isLoading = useAtomValue(loadingAtom);

	return (
		<Modal open={isLoading}>
			<Spinner width="100" height="100" fontSize="12" />
		</Modal>
	);
}
