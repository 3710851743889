/** @jsxImportSource @emotion/react */
import { useAtom } from "jotai";
import Modal from "components/layout/Modal";
import Button from "components/atoms/Button";
import { COLOR } from "styles/common";
import { stylingText } from "libs/styling";
import { detailAlertModalAtom } from "jotai/modal/detailAlertModalAtom";

export default function DetailAlertModal() {
	const [alertValue, handleModal] = useAtom(detailAlertModalAtom);

	const handleClose = () => {
		handleModal({ content: "", detailContent: [] });
	};

	return (
		<Modal open={alertValue.detailContent.length !== 0}>
			<section css={wrapper}>
				<div css={topWrapper}>
					<p css={styledTitle}>알림</p>
					<span css={contentStyle}>{alertValue.content}</span>
					<div css={styledContent}>
						{alertValue.detailContent.map((item, index) => {
							return (
								<div key={index} css={listStyle}>
									<span css={bulletPointStyle}>{`•`}</span>
									<span>{item}</span>
								</div>
							);
						})}
					</div>
				</div>
				<Button.Button css={button} customStyle="filled" onClick={handleClose}>
					확인
				</Button.Button>
			</section>
		</Modal>
	);
}

const wrapper = {
	width: "510px",
	maxHeight: "476px",
	padding: "24px",
	textAlign: "center" as const,
	backgroundColor: COLOR.white,
	borderRadius: "5px",
	boxShadow: "0px 2px 6px 2px #00000026",
};

const topWrapper = {
	display: "flex",
	flexDirection: "column" as const,
	marginBottom: "24px",
};

const styledTitle = {
	marginBottom: "16px",
	...stylingText({ size: "24", weight: "semiBold" }),
};

const styledContent = {
	textAlign: "left" as const,
	height: "275px",
	gap: "16px",
	padding: "16px",
	whiteSpace: "pre-wrap" as const,
	wordBreak: "keep-all" as const,
	border: `1px solid ${COLOR.gray400}`,
	borderRadius: "5px",
	overflowY: "scroll" as const,
	...stylingText({ size: "14", weight: "medium" }),
};

const bulletPointStyle = {
	width: "21px",
	display: "flex",
	height: "21px",
	justifyContent: "center",
	alignItems: "center",
};

const button = {
	width: "462px",
};

const listStyle = {
	display: "flex",
	color: COLOR.red,
};

const contentStyle = {
	textAlign: "left" as const,
	marginBottom: "8px",
	...stylingText({ size: "14", weight: "medium" }),
};
